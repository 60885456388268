module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"UEQ EMPLOYEE PORTAL | Universal Equations","short_name":"Employee Portal","start_url":"/","background_color":"#9EA4D3","theme_color":"#380A13","display":"minimal-ui","icon":"src/images/icon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"02d34316aa52a78a85f24e1dce17d2d4"},
    },{
      plugin: require('../node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"131702777640710"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
